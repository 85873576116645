<template>
    <div id="dataScreen" ref="dataScreen">
        
        <!-- 第二行 -->
        <div class="d-flex jc-between px-2">
            <div class="d-flex aside-width">
              <div class="react-left ml-4 react-l-s">
                <span class="react-left"></span>
                <span class="text">数据分析</span>
              </div>
              <div class="react-left ml-3" @click="quit">
                <span class="text">{{curGroupName}}</span>
              </div>
            </div>
            <div class="d-flex aside-width">
              
              <div class="react-right bg-color-black mr-3">
                <span class="text fw-b">{{curMachineId}}</span>
                <img :src="curOnline == 1 ? 'img/online.png' : 'img/offline.png'" width="30" height="30" class="text" style="position:relative;top:7px;"/>
              </div>
              <div class="react-right mr-4 react-l-s">
                <span class="react-after"></span>
                <span class="text">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>
              </div>
            </div>
        </div>
          
        <div class="content-box">
          <div class="up-box">
            <dv-border-box-12 style="width:300px;">
              <SensorList ref="sensorList" />
            </dv-border-box-12>
            <LayoutMap ref="layoutMap" />
            <!-- <LayoutMapTest res="LayoutMapTest" /> -->
            
            <Sensor1 ref="sensor1" v-if="curSensorType == 1"/>
            <Sensor2 ref="sensor2" v-else-if="curSensorType == 2"/>
            <Sensor3 ref="sensor3" v-else-if="curSensorType == 3"/>
            <Sensor4 ref="sensor4" v-else-if="curSensorType == 4"/>
            <Sensor5 ref="sensor5" v-else-if="curSensorType == 5"/>
            <Sensor6 ref="sensor6" v-else-if="curSensorType == 6"/>
            
          </div>
          
        </div>

        <!-- 退出确认模态框 -->
        <modal 
          title="提示" 
          :content='modalContent'
          :showCancle='showCancle' 
          @on-cancel='quitCancel'
          @on-confirm='quitConfirm'
          v-show='showModal'>
        </modal>
    </div>

</template>
<script>
import { formatTime } from '@/utils/index.js'
import Transfer from "@/utils/transfer.js";

import SensorList from "@/components/sensorList"
import LayoutMap from "@/components/layoutMap"
// import RegionMap from "@/components/regionMap"

import Sensor1 from "@/components/sensor/sensor1"
import Sensor2 from "@/components/sensor/sensor2"
import Sensor3 from "@/components/sensor/sensor3"
import Sensor4 from "@/components/sensor/sensor4"
import Sensor5 from "@/components/sensor/sensor5"
import Sensor6 from "@/components/sensor/sensor6"


import LayoutMapTest from "@/components/layoutMapTest"

export default {
  data() {
    return {
      timing: null,
      loading: false,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      decorationColor: ['#568aea', '#000000'],
      navItemTitle: '上蔬源可视化平台',
      //toast
      showCancle: false,
      modalContent: '确定要退出登录吗?',
      showModal: false,
      //数据
      curGroupName: this.$store.state.globalData.curGroupName,
      curMachineId: '892309070001',
      curSensorType: '1',
      curOnline: 1,
    }
  },
  components: {
    SensorList,
    // RegionMap,
    LayoutMap,
    Sensor1,
    Sensor2,
    Sensor3,
    Sensor4,
    Sensor5,
    Sensor6,
    LayoutMapTest,
  },
  mounted() {
    this.timeFn();
    
    Transfer.$on('toRefreshLayoutMap', (msg) => {
      Transfer.$emit('refreshLayoutMap', msg);
    });

    Transfer.$on('showCurMachineId', (msg) => {
      this.curMachineId = msg.curMachineId;
      this.curOnline = msg.curOnline;
    });

    Transfer.$on('navDataScreen', (msg) => {
      
      this.curSensorType = msg.curSensorType;


      //触发子组件事件  添加延时，防止子组件未初始化完成，就触发子组件事件，子组件收不到
      setTimeout(() => {
        var emitStr = "sensor" + msg.curSensorType;
        msg.curMachineId = this.curMachineId;
        
        Transfer.$emit(emitStr, msg);
        // Transfer.$emit('sensor1', msg);
        // Transfer.$emit('sensor2', msg);
        // Transfer.$emit('sensor3', msg);
        // Transfer.$emit('sensor4', msg);
        // Transfer.$emit('sensor5', msg);
        // Transfer.$emit('sensor6', msg);
      }, 200);
    });
  },
  beforeDestroy () {
    clearInterval(this.timing);
    this.timing = null;
    
    var globalData = {
      curGroupName: null,
      curGroupId: null,
      curMachine: '',
      curWebcamSerial: '',
      curStyleSize: null,
      curProtocolType: null,
      accessToken: null,
      loginToken: null,
      pumpNum: 1,
      curPumpNo: 1,
      envSensorNum: 1,
      curEnvSensorNo: 1,
      waterSensorNum: 1,
      curWaterSensorNo: 1
    }
    
    this.$store.commit("setGlobalData", globalData);
  },
  methods: {
    quit() {
      var globalData = this.$store.state.globalData;
      if(globalData.quitBtnValid != 1) {
        return;
      }
      //切换分组
      this.showCancle = true;
      this.showModal = true;
    },
    quitCancel() {
      this.showCancle = false;
      this.showModal = false;
    },
    quitConfirm() {
      //退出登录
      this.$router.push({
        path: '/'
      });
      this.$destroy();
    },
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/datascreen.scss';
</style>
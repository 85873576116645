<template>
  <div id="index" ref="appRef">
    <div class="bg">

      <dv-loading v-if="loading">
        <div style="text-align:center;">
          <h2><strong>Loading...</strong></h2>
          <h2 style="margin-top:10px;"><strong>{{percent}}</strong></h2>
        </div>
      </dv-loading>
      <div v-else class="host-body">
        <div style="display:flex;flex-direction:row;justify-content:space-between;align-items:center;margin:0px 60px 5px 60px;">
          <div style="display:flex;flex-direction:row;align-items:center;">
            <img src="/ssy-logo.png">
            <div style="margin-left:80px;font-weight:bolder;font-size:20px;color: seagreen;">
              <span>用</span>
              <span style="margin-left:30px;">科</span>
              <span style="margin-left:30px;">技</span>
              <span style="margin-left:30px;">•</span>
              <span style="margin-left:30px;">种</span>
              <span style="margin-left:30px;">未</span>
              <span style="margin-left:30px;">来</span>
            </div>
          </div>
          <span style="font-weight:bolder;font-size:28px;color: seagreen;">河北普兰特生物科技有限公司</span>
        </div>
        
        <div class="d-flex jc-center">
          <div class="dv-dec-10" style="background-color:#568aea;height:2px;"></div>
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title" @click="myNavigate('setting')">
              <span class="title-text">{{navItemTitle}}</span>
              <dv-decoration-6 class="dv-dec-6" :reverse="true" :color="['#50e3c2', '#67a1e5']"/>
            </div>
            <dv-decoration-8 class="dv-dec-8" :reverse="true" :color="decorationColor"/>
          </div>
          <div class="dv-dec-10" style="background-color:#568aea;height:2px;"></div>
        </div>
        
        <div :style="{display:dataScreenDisplay}">
          <navDataScreen ref="navDataScreen"/>
        </div>
        <div :style="{dislay:settingDisplay}">
          <setting ref="setting" v-if="settingShow" />
        </div>
        
      </div>
    </div>
  </div>

  
</template>

<script>

import drawMixin from "@/utils/drawMixin";

import Transfer from "@/utils/transfer.js";

import navDataScreen from './navDataScreen'
import setting from '@/components/setting'


export default {
  mixins: [ drawMixin ],
  data() {
    return {
      loading: true,
      percent: '0%',
      decorationColor: ['#568aea', '#000000'],
      navItemTitle: '上蔬源可视化平台',
      
      dataScreenDisplay: "block", //上蔬源可视化平台
      settingDisplay:"none",  //设置

      settingShow: false,
      dataScreenShow: true,

    }
  },
  components: {
    navDataScreen,
    setting,
  },
  mounted() {
    this.autoLogin();
  },
  beforeDestroy () {
    var globalData = {
      loginToken: '',
      navBarShow: 1,
      quitBtnValid: 1,
      layoutMapImg: '',  //布局图名称
      province: '',
      region: '',
      longitude: null,
      latitude: null,
      regionList: null,
      curGroupName: '',
      curGroupId: null,
      curMachineId: null,
      curOnline: 0,  //在线状态，1：在线，0：不在线
      curSensorNum: '',
      sensorLoop: true,  //循环展示，false：否，true：是
      coordinateX: null,  //当前设备坐标X
      coordinateY: null,  //当前设备坐标Y
      sensorCoordinateArr: null,   //当前设备传感器 坐标数组
    }
    this.$store.commit("setGlobalData", globalData);
  },
  methods: {
    autoLogin() {
      var globalData = this.$store.state.globalData;
      var curUserName = this.$store.state.curUserName;
      if(curUserName != undefined && curUserName != null && curUserName != '') {
        var that = this;
        var userName = curUserName;
        this.$store.commit("setCurUserName", '');

        var resData = "?userName=" + userName + "&password=&noPwdLogin=1";
        this.$httpApi.login(resData, function(d) {
          // console.log(JSON.stringify(d));
          if(d.result == true) {
            
            globalData = {
              loginToken: d.data.token,
              navBarShow: d.data.navBarExists,
              quitBtnValid: d.data.quitBtnExists,
              layoutMapImg: d.data.layoutMap,
              province: d.data.province,
              region: d.data.region,
              longitude: d.data.longitude,
              latitude: d.data.latitude,
              regionList: d.data.regionList,
              curGroupName: d.data.groupName,
              curGroupId: d.data.groupId,
              curMachineId: d.data.machineId,
              curOnline: 0,
              curSensorNum: 0,
              sensorLoop: true,
              coordinateX: null,
              coordinateY: null,
              sensorCoordinateArr: null,
            }
            
            that.$store.commit("setGlobalData", globalData);
            
            that.cancelLoading();
          }
          
        }, function(xhr) {
          console.log(xhr.responseText);
        });
      } else {
        
        this.cancelLoading();
      }
      
    },
    
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },

    myNavigate(nav) {
      if(nav == 'setting') {
        if(!(this.dataScreenShow == true || this.settingShow == true)) {
          return;
        }
      }
      
      var preSettingShow = this.settingShow;

      this.dataScreenDisplay = "none";
      this.settingDisplay = "none";

      this.dataScreenShow = false;
      this.settingShow = false;

      switch(nav) {
        case 'setting':
          //设置页面
          this.navItemTitle = "上蔬源可视化平台";
          if(preSettingShow == true) {
            this.settingDisplay = "none";
            this.settingShow = false;
            
            this.dataScreenDisplay = "block";
            this.dataScreenShow = true;
          } else {
            this.settingDisplay = "block";
            this.settingShow = true;
          }
          break;
          
        default:break;
      }
      
    }

  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

</style>


<template>
    <div id="layoutMap">
        <div class="bg-color-black">
            <div id="sensorTip" class="sensor-tip">
                <div v-for="(item, index) in sensorData" :key="index">
                    <span class="sensor-data-item">{{item.name}}{{item.value}}</span>
                </div>
                <!-- <span style="height:24px;">编号1</span>
                <span style="height:24px;">土壤温度 : 1</span>
                <span style="height:24px;">土壤湿度 : 1</span>
                <span style="height:24px;">土壤EC : 1</span>
                <span style="height:24px;">土壤PH : 1</span> -->
            </div>
            <dv-flyline-chart-enhanced :config="config" :dev="false" style="width:100%;height:100%;" />
        </div>
    </div>
</template>
<script>

import Transfer from "@/utils/transfer.js";
import Global from "@/config/global.js";

export default {
    data() {
        return {
            // config: {
            //     points: [
            //         {
            //             name: '郑州', coordinate: [0.48, 0.35],
            //             halo: {
            //                 show: true,
            //             },
            //             icon: {
            //                 src: 'img/flylineChart/mapCenterPoint.png',
            //                 width: 30,
            //                 height: 30
            //             },
            //             text: {
            //                 show: false
            //             }
            //         },
            //         {name: '新乡', coordinate: [0.52, 0.23]},
            //         {name: '焦作', coordinate: [0.43, 0.29]},
            //         {name: '开封', coordinate: [0.59, 0.35]},
            //         {name: '许昌', coordinate: [0.53, 0.47]},
            //         {name: '平顶山', coordinate: [0.45, 0.54]},
            //         {name: '洛阳', coordinate: [0.36, 0.38]},
            //         {name: '周口', coordinate: [0.62, 0.55]},
            //         {name: '漯河', coordinate: [0.56, 0.56]},
            //         {name: '南阳', coordinate: [0.37, 0.66]},
            //         {name: '信阳', coordinate: [0.55, 0.81]},
            //         {name: '驻马店', coordinate: [0.55, 0.67]},
            //         {name: '济源', coordinate: [0.37, 0.29]},
            //         {name: '三门峡', coordinate: [0.20, 0.36]},
            //         {name: '商丘', coordinate: [0.76, 0.41]},
            //         {name: '鹤壁', coordinate: [0.59, 0.18]},
            //         {name: '濮阳', coordinate: [0.68, 0.17]},
            //         {name: '安阳', coordinate: [0.59, 0.10]}
            //     ],
            //     lines: [
            //         {source: '新乡', target: '郑州'},
            //         {source: '焦作', target: '郑州'},
            //         {source: '开封', target: '郑州'},
            //         {source: '许昌', target: '郑州'},
            //         {source: '平顶山', target: '郑州'},
            //         {source: '洛阳', target: '郑州'},
            //         {source: '周口', target: '郑州'},
            //         {source: '漯河', target: '郑州'},
            //         {source: '南阳', target: '郑州'},
            //         {source: '信阳', target: '郑州'},
            //         {source: '驻马店', target: '郑州'},
            //         {source: '济源', target: '郑州'},
            //         {source: '三门峡', target: '郑州'},
            //         {source: '商丘', target: '郑州'},
            //         {source: '鹤壁', target: '郑州'},
            //         {source: '濮阳', target: '郑州'},
            //         {source: '安阳', target: '郑州'}
            //     ],
            //     icon: {
            //         show: true,
            //         src: 'img/flylineChart/mapPoint.png'
            //     },
            //     text: {
            //         show: true,
            //     },
            //     bgImgSrc: '/img/flylineChart/map.jpg'
            // }
            config: {
                points: [
                    {
                        name: '基站', 
                        coordinate: [0.53, 0.49],
                        halo: {
                            color: '#FF1493',
                            show: true,
                        },
                        icon: {
                            src: 'img/layoutMap/mapCenterPoint.png',
                            width: 30,
                            height: 30
                        },
                        text: {
                            show: false,
                            color: '#f90613'
                        }
                    },
                    // {name: '新乡', coordinate: [0.52, 0.23], text: {color: '#d81e06',fontSize:'15'}},
                    // {name: '焦作', coordinate: [0.43, 0.29], text: {color: '#d81e06',fontSize:'15'}},
                    // {name: '开封', coordinate: [0.59, 0.35], text: {color: '#d81e06',fontSize:'15'}},
                    // {name: '平顶山', coordinate: [0.45, 0.54], text: {color: '#d81e06',fontSize:'15'}},
                    // {name: '洛阳', coordinate: [0.36, 0.38], text: {color: '#d81e06',fontSize:'15'}},
                    // {name: '周口', coordinate: [0.62, 0.55], text: {color: '#d81e06',fontSize:'15'}},
                    // {name: '漯河', coordinate: [0.56, 0.56], text: {color: '#d81e06',fontSize:'15'}},
                    // {name: '南阳', coordinate: [0.37, 0.66], text: {color: '#d81e06',fontSize:'15'}},
                    // {name: '信阳', coordinate: [0.55, 0.81], text: {color: '#d81e06',fontSize:'15'}},
                    // {name: '驻马店', coordinate: [0.55, 0.67], text: {color: '#d81e06',fontSize:'15'}},
                    // {name: '济源', coordinate: [0.37, 0.29], text: {color: '#d81e06',fontSize:'15'}},
                    // {name: '三门峡', coordinate: [0.20, 0.36], text: {color: '#d81e06',fontSize:'15'}},
                    // {name: '商丘', coordinate: [0.76, 0.41], text: {color: '#d81e06',fontSize:'15'}},
                    // {name: '鹤壁', coordinate: [0.59, 0.18], text: {color: '#d81e06',fontSize:'15'}},
                    // {name: '濮阳', coordinate: [0.68, 0.17], text: {color: '#d81e06',fontSize:'15'}},
                    // {name: '安阳', coordinate: [0.59, 0.10], text: {color: '#d81e06',fontSize:'15'}}
                ],
                lines: [
                    // {source: '新乡', target: '基站',color:'#d4237a', width:'2'},
                    // {source: '焦作', target: '基站',color:'#d4237a', width:'2'},
                    // {source: '开封', target: '基站',color:'#d4237a', width:'2'},
                    // {source: '平顶山', target: '基站',color:'#d4237a', width:'2'},
                    // {source: '洛阳', target: '基站',color:'#d4237a', width:'2'},
                    // {source: '周口', target: '基站',color:'#d4237a', width:'2'},
                    // {source: '漯河', target: '基站',color:'#d4237a', width:'2'},
                    // {source: '南阳', target: '基站',color:'#d4237a', width:'2'},
                    // {source: '信阳', target: '基站',color:'#d4237a', width:'2'},
                    // {source: '驻马店', target: '基站',color:'#d4237a', width:'2'},
                    // {source: '济源', target: '基站',color:'#d4237a', width:'2'},
                    // {source: '三门峡', target: '基站',color:'#d4237a', width:'2'},
                    // {source: '商丘', target: '基站',color:'#d4237a', width:'2'},
                    // {source: '鹤壁', target: '基站',color:'#d4237a', width:'2'},
                    // {source: '濮阳', target: '基站',color:'#d4237a', width:'2'},
                    // {source: '安阳', target: '基站',color:'#d4237a', width:'2'}
                ],
                icon: {
                    show: true,
                    src: 'img/layoutMap/mapPoint.png'
                },
                text: {
                    show: true,
                },
                // bgImgSrc: '/img/layoutMap/map.png'
                bgImgSrc: Global.layoutMapPath + this.$store.state.globalData.layoutMapImg,
            },
            activeSensorNum: 1,
            pointHaloColor: '#8A2BE2',
            pointText: {
                color: '#FFA500',
                fontSize:'15'
            },
            flyLineColor:"#d4237a",
            sensorNumPointArr: [], //传感器 点位配置Point索引 与 设备编号 对照表
            sensorData: [],  //传感器当前数据
        }
    },
    components: {
        
    },
    mounted() {
        //刷新传感器当前数据
        Transfer.$on('refreshCurSensorData', (msg) => {
            switch(msg.sensorType) {
                case 1:
                    var curSensorData = [
                        {name:"编号" + msg.sensorNum, value: ''},
                        {name:"水温 (℃) : ", value: (msg.waterTemp == null ? '' : msg.waterTemp)},
                        {name:"营养指数 (uS/cm) : ", value: (msg.waterEC == null ? '' : msg.waterEC)},
                        {name:"水位 (cm) : ", value: (msg.waterLevel == null ? '' : msg.waterLevel)},
                    ];
                    this.sensorData = curSensorData;
                    break;
                case 2:
                    var curSensorData = [
                        {name:"编号" + msg.sensorNum, value: ''},
                        {name:"水PH : ", value: (msg.waterPH == null ? '' : msg.waterPH)},
                    ];
                    this.sensorData = curSensorData;
                    break;
                case 3:
                    var curSensorData = [
                        {name:"编号" + msg.sensorNum, value: ''},
                        {name:"土壤温度 (℃) : ", value: (msg.soilTemp == null ? '' : msg.soilTemp)},
                        {name:"土壤湿度 (%) : ", value: (msg.soilHumidity == null ? '' : msg.soilHumidity)},
                        {name:"土壤EC (uS/cm) : ", value: (msg.soilEC == null ? '' : msg.soilEC)},
                    ];
                    this.sensorData = curSensorData;
                    break;
                case 4:
                    var curSensorData = [
                        {name:"编号" + msg.sensorNum, value: ''},
                        {name:"土壤温度 (℃) : ", value: (msg.soilTemp == null ? '' : msg.soilTemp)},
                        {name:"土壤湿度 (%) : ", value: (msg.soilHumidity == null ? '' : msg.soilHumidity)},
                        {name:"土壤EC (uS/cm) : ", value: (msg.soilEC == null ? '' : msg.soilEC)},
                        {name:"土壤PH : ", value: (msg.soilPH == null ? '' : msg.soilPH)},
                        {name:"土壤氮 (mg/kg) : ", value: (msg.soilNitrogen == null ? '' : msg.soilNitrogen)},
                        {name:"土壤磷 (mg/kg) : ", value: (msg.soilPhosphorus == null ? '' : msg.soilPhosphorus)},
                        {name:"土壤钾 (mg/kg) : ", value: (msg.soilPotassium == null ? '' : msg.soilPotassium)},
                    ];
                    this.sensorData = curSensorData;
                    break;
                case 5:
                    var curSensorData = [
                        {name:"编号" + msg.sensorNum, value: ''},
                        {name:"环境温度 (℃) : ", value: (msg.envTemp == null ? '' : msg.envTemp)},
                        {name:"环境湿度 (%) : ", value: (msg.envHumidity == null ? '' : msg.envHumidity)},
                        {name:"光照强度 (LX) : ", value: (msg.envIllumination == null ? '' : msg.envIllumination)},
                    ];
                    this.sensorData = curSensorData;
                    break;
                case 6:
                    var curSensorData = [
                        {name:"编号" + msg.sensorNum, value: ''},
                        {name:"环境温度 (℃) : ", value: (msg.envTemp == null ? '' : msg.envTemp)},
                        {name:"环境湿度 (%) : ", value: (msg.envHumidity == null ? '' : msg.envHumidity)},
                        {name:"光照强度 (LX) : ", value: (msg.envIllumination == null ? '' : msg.envIllumination)},
                        {name:"二氧化碳浓度 (ppm) : ", value: (msg.envCO2 == null ? '' : msg.envCO2)},
                        {name:"大气压 (kPa) : ", value: (msg.atmos == null ? '' : msg.atmos)},
                        {name:"风速 (m/s) : ", value: (msg.windSpeed == null ? '' : msg.windSpeed)},
                        {name:"风向 (°) : ", value: (msg.windDirection == null ? '' : msg.windDirection)},
                        {name:"雨强 (mm/min) : ", value: (msg.rainIntensity == null ? '' : msg.rainIntensity)},
                        {name:"蒸发量 (mm) : ", value: (msg.evapCapacity == null ? '' : msg.evapCapacity)},
                    ];
                    this.sensorData = curSensorData;
                    break;
            }
            
        });

        Transfer.$on('refreshLayoutMap', (msg) => {
            this.initCoordinate();

            //this.initClickEvent();
            //this.initCurSensorTip();
        });
        Transfer.$on('refreshLayoutMapPointActive', (msg) => {
            var ind = this.sensorNumPointArr[this.activeSensorNum];
            this.config.points[ind].halo.show = false;
            
            var toInd = this.sensorNumPointArr[msg.curSensorNum];
            this.config.points[toInd].halo.show = true;
            this.activeSensorNum = msg.curSensorNum;
            
            this.config = { ...this.config };
            
            //this.initClickEvent();
            //this.initCurSensorTip();
        });
        
    },
    beforeDestroy () {

    },
    methods: {
        initCoordinate() {
            var globalData = this.$store.state.globalData;
            //基站
            this.config.points[0].coordinate[0] = globalData.coordinateX;
            this.config.points[0].coordinate[1] = globalData.coordinateY;

            //传感器
            var arr = globalData.sensorCoordinateArr;
            for(var i = 0; i < arr.length; i++) {
                var sensorNum = arr[i].sensorNum;
                var coordinateX = arr[i].coordinateX;
                var coordinateY = arr[i].coordinateY;

                //传感器点
                var item = {};
                item.name = "编号" + sensorNum;
                item.coordinate = [coordinateX, coordinateY];
                item.text = this.pointText;
                
                //免密登陆会重置缓存，不需判断
                //非免密登陆页，不会重置缓存，需判断缓存传感器编号
                if((globalData.curSensorNum == 0 && i == 0) || (globalData.curSensorNum != 0 && globalData.curSensorNum == sensorNum)) {
                // if(i == 0) {
                    item.halo = {color: this.pointHaloColor,show: true};
                    this.activeSensorNum = sensorNum;
                } else {
                    item.halo = {color: this.pointHaloColor,show: false};
                }
                this.sensorNumPointArr[sensorNum] = i + 1;
                this.config.points[i + 1] = item;
                

                //飞线 传感器-基站
                var lineItem = {};
                lineItem.source = "编号" + sensorNum;
                lineItem.target = "基站";
                lineItem.color = this.flyLineColor;
                lineItem.width = "2";
                
                this.config.lines[i] = lineItem;
            }

            this.config = { ...this.config };
        },

        // initCurSensorTip() {
        //     var curSensorNum = this.activeSensorNum;

        //     var layoutMapEle = document.getElementById("layoutMap");
        //     var textChild = layoutMapEle.getElementsByTagName("text");
        //     for(var i = 0; i < textChild.length; i++) {
        //         var itemEle = textChild[i];
        //         var sensorNumName = itemEle.innerHTML;
        //         var ind = sensorNumName.replace(/[^\d]/g, '');
        //         if(ind == curSensorNum) {
        //             var sensorImgEleArr = itemEle.parentNode.getElementsByTagName("image");
        //             var sensorImgEle = sensorImgEleArr[0];
        //             var x = sensorImgEle.getAttribute("x");
        //             var y = sensorImgEle.getAttribute("y");
                    
        //             x = parseFloat(x);
        //             y = parseFloat(y);
        //             x += 334;
        //             y += 230;

        //             //偏移
        //             x += 15;
        //             y -= 10;
                    
        //             var sensorTipEle = document.getElementById("sensorTip");
        //             sensorTipEle.style.top = y + 'px';
        //             sensorTipEle.style.left = x + 'px';
        //         }

        //     }
        // },
        // initClickEvent() {
        //     var layoutMapEle = document.getElementById("layoutMap");
        //     var textChild = layoutMapEle.getElementsByTagName("text");
            
        //     for(var i = 0; i < textChild.length; i++) {
        //         var itemEle = textChild[i];
        //         if(itemEle.innerHTML == "基站") {
        //             return;
        //         }
        //         var parentEle = itemEle.parentNode;
        //         parentEle.addEventListener("click", function(e) {
        //             var childTextEle = this.getElementsByTagName("text");
        //             var sensorNumName = childTextEle[0].innerHTML;
        //             var ind = sensorNumName.replace(/[^\d]/g, '');
                    
        //             var childImageEle = this.getElementsByTagName("image");
        //             var sensorNumImgEle = childImageEle[0];
        //             var x = sensorNumImgEle.getAttribute("x");
        //             var y = sensorNumImgEle.getAttribute("y");

        //             console.log(ind);
        //             console.log(x);
        //             console.log(y);
        //         });
                
        //     }
            
        // },
    }
}
</script>
<style lang="scss" scoped>

#layoutMap {
  $box-width: 1000px;
  $box-height: 1000px;
  padding: 16px;
  height: $box-height;
  min-width: $box-width;
  border-radius: 10px;

  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }

  .sensor-tip {
    position:absolute;
    //width:100px;
    padding:10px;
    display:flex;
    flex-direction:column;
    background-color: rgba(29, 28, 28, 0.897);
    border-radius:5px;
    top:230px;
    left:334px;
  }

  .sensor-data-item {
    display:block;
    height:25px;
  }
}

</style>

<template>
    <div id="layoutMapTest">
        <div class="bg-color-black" style="position:absolute;">
            <div style="position:absolute;">
                <svg>
                    <!-- <path d="M10 80 Q 95 10 180 80" stroke="orange" fill="transparent"/> -->
                    <path d="M50 50 Q 300 100 500 500" stroke="orange" fill="transparent"/>
                </svg>
            </div>
            
            <!-- <div style="width:10px;height:10px;background: linear-gradient(0deg, orange 0, transparent 100%);" class="line1"></div> -->
            <!-- <div style="width:150px;height:10px;background: radial-gradient(circle at 100%, orange 0, transparent 100%);" class="line1"></div> -->
            <img src="img/layoutMap/mapPoint.png" width="15" height="15" style="position:absolute;top:100px;left:100px;" class="line1"/>
            <img src="img/layoutMap/mapCenterPoint.png" width="50" height="50" style="position:absolute;top:400px;left:400px;"/>
            <img src="img/layoutMap/map.png" width="100%" height="100%"/>
        </div>
        
    </div>
</template>
<script>


export default {
    data() {
        return {
            
        }
    },
    components: {
        
    },
    mounted() {
        this.initlayoutMap();
    },
    beforeDestroy () {

    },
    methods: {
        initlayoutMap() {
            
            
        }
    }
}
</script>
<style lang="scss" scoped>

#layoutMapTest{
    $box-width: 1000px;
    $box-height: 1000px;
    padding: 16px;
    height: $box-height;
    min-width: $box-width;
    border-radius: 10px;

    .bg-color-black {
        height: $box-height - 30px;
        border-radius: 10px;
    }

    @keyframes lightRun {
        0%{
            offset-distance: 0%;
        }
        100%{
            offset-distance: 100%;
        }
    }
    .line1 {
        //overflow: hidden;
        //position: absolute;
        // offset-path: path('M50 50 L325 325');
        offset-path: path('M50 50 Q 200 100, 325 325');
        //offset-distance: 0;
        animation: lightRun 2s linear infinite;
    }

    
}

</style>
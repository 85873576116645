

<template>
    <div>
      <Echart
        :options="options"
        id="sensor6Chart2"
        height="440px"
        width="100%"
      ></Echart>
    </div>
</template>

<script>

import Echart from '@/common/echart'
export default {
    data () {
      return {
        options: {},
        // 定义颜色
        colorList: {
            linearBtoG: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 1,
                y2: 0,
                colorStops: [
                    {
                        offset: 0,
                        color: '#1c98e8'
                    },
                    {
                        offset: 1,
                        color: '#28f8de'
                    }
                ]
            },
            areaBtoG: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                    {
                        offset: 0,
                        color: 'rgba(35,184,210,.2)'
                    },
                    {
                        offset: 1,
                        color: 'rgba(35,184,210,0)'
                    }
                ]
            }
        }
      };
    },
    components: {
      Echart,
    },
    props: {
      cdata: {
        type: Object,
        default: () => ({})
      },
    },
    watch: {
      cdata: {
        handler (newData) {
          this.options = {
            tooltip: {
              trigger: 'axis',
            },
            legend: {
              data: ['大气压(kPa)', '风速(m/s)', '风向(°)', '雨强(mm/min)', '蒸发量(mm)'],
              textStyle: {
                color: "#B4B4B4"
              },
              top: "5%"
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: {
              // name:'小时',
              // nameLocation:'start',
              type: 'category',
              boundaryGap: false,
              data: newData.times,
            },
            yAxis: {
              type: 'value',
              splitLine: {
                show: true,
                lineStyle: {
                  color: '#fff',
                  opacity: 0.1
                }
              },
            },
            series: [
              {
                name: '大气压(kPa)',
                type: 'line',
                // stack: 'Total',
                data: newData.atmosData
              },
              {
                name: '风速(m/s)',
                type: 'line',
                // stack: 'Total',
                data: newData.windSpeedData
              },
              {
                name: '风向(°)',
                type: 'line',
                // stack: 'Total',
                data: newData.windDirectionData
              },
              {
                name: '雨强(mm/min)',
                type: 'line',
                // stack: 'Total',
                data: newData.rainIntensityData
              },
              {
                name: '蒸发量(mm)',
                type: 'line',
                // stack: 'Total',
                data: newData.evapCapacityData
              }
            ]
          }
        },
        immediate: true,
        deep: true
      },
    },
}
</script>


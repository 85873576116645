
<template>
    <div id="setting" ref="setting">
        <h1 @click="settingFunEvent('setList')">{{title}}</h1>
        <div class="content-box" :style="{display:setListDisplay}">
            <div class="content-box-layer">
                <dv-border-box-9>
                    <div class="content-item" @click="settingFunEvent('sensorCoordinate')">
                        <span>传感器坐标</span>
                    </div>
                </dv-border-box-9>
            </div>
        </div>
        <div :style="{display:setContentListDisplay}">
            <sensorCoordinate v-if="sensorCoordinateShow"/>
        </div>
       
    </div>
</template>
<script>

import sensorCoordinate from "./sensorCoordinate"

export default {
    data() {
        return {
            title: "设置",
            setListDisplay: "flex",
            setContentListDisplay: "none",
            sensorCoordinateShow: false
        }
    },
    components: {
        sensorCoordinate,
    },
    mounted() {

    },
    beforeDestroy () {

    },
    methods: {
        settingFunEvent(item) {
            this.setListDisplay = "none";
            this.setContentListDisplay = "none";
            this.sensorCoordinateShow = false;

            switch(item) {
                case 'setList':
                    this.title = "设置";
                    this.setListDisplay = "flex";
                    break;
                case 'sensorCoordinate': 
                    this.title = "设置 - 传感器坐标";
                    this.setContentListDisplay = "block";
                    this.sensorCoordinateShow = true;
                    break;
                default: break;
            }
        }
    }
}

</script>
<style lang="scss" scoped>
@import '@/assets/scss/setting.scss';
</style>

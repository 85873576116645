
<template>
    <div id="sensorCoordinate" ref="sensorCoordinate">
        <div style="display:flex;flex-direction:row;">
            <div>
                <img id="layoutMapImg" :width="width" :height="height" :src="layoutMapImg" @click="getMouseXY"/>

                <div v-for="(item, index) in coordinateArr" :key="index">
                    <div class="coor-img-item" :style="{left:item.left + 'px',marginTop:item.marginTop + 'px'}">
                        <img :width="item.sensorNum == 0 ? 30 : 15" :height="item.sensorNum == 0 ? 30 : 15" :src="item.sensorNum == 0 ? 'img/layoutMap/mapCenterPoint.png' : 'img/layoutMap/mapPoint.png'"/>
                        <span style="color:#faa405;font-size:16px;">{{item.sensorNum == 0 ? '基站' : '编号' + item.sensorNum}}</span>
                    </div>
                </div>
            </div>
            
            <div style="display:flex;flex-direction:column;align-items:center;">
                
                <div class="save-btn" @click="saveEvent()">保 存</div>
                <div style="overflow:hidden;margin-top:20px;height:840px;">
                    <div id="coorList" class="list">
                        <div v-for="(item, index) in coordinateArr" :key="index">
                            <div class="item" :class="item.active == true ? 'active' : ''" @click="coordinateChange(index)">
                                <span>{{item.name}}</span>
                                <div style="margin-top:10px;display:flex;flex-direction:row;align-items:center;">
                                    <span>X:</span>
                                    <span style="margin-left:5px;padding:10px 12px;border:1px solid #257dff;border-radius:3px;width:62px;height:40px;">{{item.coordinateX}}</span>
                                    <span style="margin-left:10px;">Y:</span>
                                    <span style="margin-left:5px;padding:10px 12px;border:1px solid #257dff;border-radius:3px;width:62px;height:40px;">{{item.coordinateY}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="up-down">
                    <img width="60" height="50" src="img/up.png" @click="scrollEvent('up')" :style="{display:btnUpDisplay}"/>
                    <img width="60" height="50" src="img/down.png" @click="scrollEvent('down')" style="margin-left:50px;" :style="{display:btnDownDisplay}" />
                </div>
            </div>
            
        </div>
        
        <div class="fun-tip" :style="{display:tipdisplay}">
            <span style="color:#d3d6dd;">{{tipText}}</span>
        </div>
    </div>
</template>
<script>

import Transfer from "@/utils/transfer.js";
import Global from "@/config/global.js";

export default {
    data() {
        return {
            layoutMapImg: Global.layoutMapPath + this.$store.state.globalData.layoutMapImg,
            width: 980,
            height: 980,
            curCoordinateInd: 0,
            coordinateArr: [
                // {"sensorNum":0, "sensorType":3, "name":"基站", "coordinateX":'', 'coordinateY':'', 'active':true},
                // {"sensorNum":1, "sensorType":3, "name":"编号1", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":2, "sensorType":3, "name":"编号2", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":3, "sensorType":3, "name":"编号3", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":4, "sensorType":3, "name":"编号4", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":5, "sensorType":3, "name":"编号5", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":6, "sensorType":3, "name":"编号6", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":7, "sensorType":3, "name":"编号7", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":8, "sensorType":3, "name":"编号8", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":9, "sensorType":3, "name":"编号9", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":10, "sensorType":3, "name":"编号10", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":11, "sensorType":3, "name":"编号11", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":12, "sensorType":3, "name":"编号12", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":13, "sensorType":3, "name":"编号13", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":14, "sensorType":3, "name":"编号14", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":15, "sensorType":3, "name":"编号15", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":16, "sensorType":3, "name":"编号16", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":17, "sensorType":3, "name":"编号17", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":18, "sensorType":3, "name":"编号18", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":19, "sensorType":3, "name":"编号19", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":20, "sensorType":3, "name":"编号20", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":21, "sensorType":6, "name":"编号21", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":22, "sensorType":6, "name":"编号22", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":23, "sensorType":6, "name":"编号23", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":24, "sensorType":6, "name":"编号24", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":25, "sensorType":6, "name":"编号25", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":26, "sensorType":6, "name":"编号26", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":27, "sensorType":6, "name":"编号27", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":28, "sensorType":6, "name":"编号28", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":29, "sensorType":6, "name":"编号29", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":30, "sensorType":6, "name":"编号30", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":31, "sensorType":6, "name":"编号31", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":32, "sensorType":6, "name":"编号32", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":33, "sensorType":6, "name":"编号33", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":34, "sensorType":6, "name":"编号34", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":35, "sensorType":6, "name":"编号35", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":36, "sensorType":6, "name":"编号36", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":37, "sensorType":6, "name":"编号37", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":38, "sensorType":6, "name":"编号38", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":39, "sensorType":6, "name":"编号39", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":40, "sensorType":6, "name":"编号40", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":41, "sensorType":6, "name":"编号41", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":42, "sensorType":6, "name":"编号42", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":43, "sensorType":6, "name":"编号43", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":44, "sensorType":6, "name":"编号44", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":45, "sensorType":6, "name":"编号45", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":46, "sensorType":6, "name":"编号46", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":47, "sensorType":6, "name":"编号47", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":48, "sensorType":6, "name":"编号48", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":49, "sensorType":6, "name":"编号49", "coordinateX":'', 'coordinateY':'', 'active':false},
                // {"sensorNum":50, "sensorType":6, "name":"编号50", "coordinateX":'', 'coordinateY':'', 'active':false},
            ],
            tipdisplay: "none",
            tipText: '',
            curScrollHeight:0,
            btnUpDisplay: 'block',
            btnDownDisplay: 'block',
        }
    },
    components: {
        
    },
    mounted() {
        this.initSensorCoordinate();
        this.initBtnUpDown();
    },
    beforeDestroy () {

    },
    methods: {
        //初始化上下滚动键
        initBtnUpDown() {
            var coorListEle = document.getElementById("coorList");
            var scrollHeight = coorListEle.scrollHeight;
            var clientHeight = coorListEle.parentNode.clientHeight;
            
            if(scrollHeight <= clientHeight) {
                this.btnUpDisplay = 'none';
                this.btnDownDisplay = 'none';
            }
        },
        //滚动事件
        scrollEvent(flag) {
            //向上滚动
            var coorListEle = document.getElementById("coorList");
            var scrollHeight = coorListEle.scrollHeight;
            var clientHeight = coorListEle.parentNode.clientHeight;

            if(scrollHeight <= clientHeight) {
                return;
            }
            if((flag == 'down' && this.curScrollHeight <= 0)
                || (flag == 'up' && this.curScrollHeight >= (scrollHeight - clientHeight))) {
                return;
            }
            
            var itemEleArr = document.querySelectorAll('#coorList .item');
            var itemClientHeight = itemEleArr[0].clientHeight;
            
            if(flag == 'up') {
                this.curScrollHeight += itemClientHeight;
            } else if(flag == 'down') {
                this.curScrollHeight -= itemClientHeight;
            }
            var top = -this.curScrollHeight;
            coorListEle.style.transform = "translateY(" + top + "px)";
            coorListEle.style.transition = "all 1s";

        },
        //根据缓存 初始化坐标数值
        initSensorCoordinate() {
            var globalData = this.$store.state.globalData;

            var curCoordinateArr = [];
            //基站
            curCoordinateArr[0] = {};
            curCoordinateArr[0].sensorNum = 0;
            curCoordinateArr[0].sensorType = 0;
            curCoordinateArr[0].name = "基站";
            curCoordinateArr[0].coordinateX = globalData.coordinateX;
            curCoordinateArr[0].coordinateY = globalData.coordinateY;
            curCoordinateArr[0].active = true;

            //基站 图片位置
            var style = this.getCoordinateImgStyle(globalData.coordinateX, globalData.coordinateY, 0);
            curCoordinateArr[0].left = style.left;
            curCoordinateArr[0].marginTop = style.marginTop;

            
            var arr = globalData.sensorCoordinateArr;
            for(var i = 0; i < arr.length; i++) {
                var item = {};
                item.sensorNum = arr[i].sensorNum;
                item.sensorType = arr[i].sensorType;
                item.sensorStatus = arr[i].sensorStatus;
                item.name = "编号" + arr[i].sensorNum;
                item.coordinateX = (arr[i].coordinateX == null ? '' : arr[i].coordinateX);
                item.coordinateY = (arr[i].coordinateY == null ? '' : arr[i].coordinateY);
                item.active = false;

                //设置图片位置
                var style = this.getCoordinateImgStyle(arr[i].coordinateX, arr[i].coordinateY, arr[i].sensorNum);
                item.left = style.left;
                item.marginTop = style.marginTop;

                curCoordinateArr[i + 1] = item;
            }
            
            this.coordinateArr = curCoordinateArr;
        },
        //更新坐标数值
        coordinateChange(ind) {
            this.coordinateArr[this.curCoordinateInd].active = false;
            this.coordinateArr[ind].active = true;
            this.curCoordinateInd = ind;
        },
        //获取坐标图片style
        getCoordinateImgStyle(coordinateX, coordinateY, sensorNum) {
            var style = {};
            if(sensorNum == 0) {
                //基站
                style.left = coordinateX * this.width + 5; 
                style.marginTop = coordinateY * this.height - this.height - 20;
            } else {
                //传感器
                style.left = coordinateX * this.width - 8; 
                style.marginTop = coordinateY * this.height - this.height - 11;
            }
            
            return style;
        },
        //获取鼠标点击 相对图片位置  左上角0，0
        getMouseXY(e) {
            var offsetX = e.offsetX, offsetY = e.offsetY;
            var width = this.width, height = this.height
            var relativeX = (offsetX / width).toFixed(2);
            var relativeY = (offsetY / height).toFixed(2);

            // console.log("relativeX=" + relativeX);
            // console.log("relativeY=" + relativeY);
            
            this.coordinateArr[this.curCoordinateInd].coordinateX = relativeX;
            this.coordinateArr[this.curCoordinateInd].coordinateY = relativeY;
            
            //设置图片位置
            var style = this.getCoordinateImgStyle(relativeX, relativeY, this.coordinateArr[this.curCoordinateInd].sensorNum);
            this.coordinateArr[this.curCoordinateInd].left = style.left;
            this.coordinateArr[this.curCoordinateInd].marginTop = style.marginTop;
            
        },
        //保存数据库
        saveEvent() {
            let that = this;
            var globalData = this.$store.state.globalData;
            var curMachineId = globalData.curMachineId;

            var arr = this.coordinateArr;
            var resData = [];
            var storeData = [], storeInd = 0;
            for(var i = 0 ; i < arr.length; i++) {
                var item = {};
                item.machineId = curMachineId;
                item.sensorNum = arr[i].sensorNum;
                item.sensorType = arr[i].sensorType;
                item.coordinateX = arr[i].coordinateX;
                item.coordinateY = arr[i].coordinateY;

                resData[i] = item;

                //配置成功，本地缓存更新
                if(arr[i].sensorNum != 0) {
                    var storeItem = {};
                    storeItem.sensorNum = arr[i].sensorNum;
                    storeItem.sensorType = arr[i].sensorType;
                    storeItem.sensorStatus = arr[i].sensorStatus;
                    storeItem.coordinateX = arr[i].coordinateX;
                    storeItem.coordinateY = arr[i].coordinateY;
                    storeData[storeInd++] = storeItem;
                }
                
            }
            

            this.$httpApi.updateSensorCoordinate(resData, function(data) {
                // console.log(JSON.stringify(data)); 
                if(data.message == true) {
                    that.$store.commit("setCoordinateX", arr[0].coordinateX);
                    that.$store.commit("setCoordinateY", arr[0].coordinateY);
                    that.$store.commit("setSensorCoordinateArr", storeData);
                    Transfer.$emit('toRefreshLayoutMap', "");

                    that.tipShowFun(that, "修改成功");
                } else {
                    that.tipShowFun(that, "修改失败");
                }
            }, function(xhr) {
                console.log(xhr.responseText);
                that.tipShowFun(that, "修改异常");
            });
        },
        //消息提示
        tipShowFun(that, txt) {
            that.tipText = txt;
            that.tipdisplay = "block";
            setTimeout(() => {
                that.tipdisplay = "none";
                that.tipText = '';
            }, 1000);
        },
    }

}

</script>
<style lang="scss" scoped>

@import '@/assets/scss/sensorCoordinate.scss';

</style>
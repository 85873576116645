
<template>
    <div id="sensor3" ref="sensor3" class="body-box">
        
        <div class="data-view-list">
            <div class="bg-color-black item" v-for="(item, index) in titleItem" :key="item.title">
                <p class="colorBlue fw-b fs-xl my-text">{{ item.title }}</p>
                <div v-if="index === 0" style="padding-top:12px;">
                    <span style="color:rgba(0, 255, 255, 0.76);font-weight: bold;font-size:18px;">{{batteryLevelArr[item.number.number]}}</span>
                </div>
                <div v-else>
                    <dv-digital-flop class="my-class dv-dig-flop pl-6" :config="item.number"/>
                </div>
            </div>
        </div>
        <div class="bg-color-black" style="height:450px;">
            <dv-border-box-13>
                <Chart1 :cdata="cdata" />
            </dv-border-box-13>
        </div>
        <div class="bg-color-black" style="height:450px;margin-top:5px;">
            <dv-border-box-12>
                <Chart2 :cdata="cdata" />
            </dv-border-box-12>
        </div>
        
    </div>
</template>
<script>

import { formatTime } from '@/utils/index.js'
import Transfer from "@/utils/transfer.js";
import Chart1 from './chart1.vue'
import Chart2 from './chart2.vue'


export default {
    data() {
        return {
            batteryLevelArr: ['', '满电', '中等', '低电量', '更换电池或充电'],
            titleItem: [
                {
                    title: '电池电量',
                    number: {
                        number: [1],
                        toFixed: 0,
                        textAlign: 'center',
                        content: '{nt}',
                        style: {
                        fontSize: 26
                        }
                    }
                },
                {
                    title: '电池电压(mv)',
                    number: {
                        number: [15],
                        toFixed: 0,
                        textAlign: 'center',
                        content: '{nt}',
                        style: {
                        fontSize: 26
                        }
                    }
                },
                {
                    title: '信号强度(dBm)',
                    number: {
                        number: [-60],
                        toFixed: 0,
                        textAlign: 'center',
                        content: '{nt}',
                        style: {
                        fontSize: 26
                        }
                    }
                },
                
            ],
            //传感器数据
            cdata: {
                // times: [ "1", "2",  "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"],
                // soilTempData: [20, 25, 25, 26, 22, 20, 15, 18, 16, 28, 30, 33, 35, 32, 31, 36, 28, 25, 20, 14, 15, 13, 11, 12],
                // soilHumidityData: [10, 20, 10, 5, 20, 30, 35, 20, 23, 25, 22, 21, 24, 26, 28, 20, 19, 18, 20, 24, 25, 23, 25, 28],
                // soilECData: [10, 20, 10, 5, 20, 30, 35, 20, 23, 25, 22, 21, 24, 26, 28, 20, 19, 18, 20, 24, 25, 23, 25, 28],
                // soilPHData: [20, 3, 10, 15, 23, 70, 15, 10, 20, 21, 2, 2, 10, 20, 25, 15, 9, 15, 16, 18, 17, 5, 10, 20]
                times: [],
                soilTempData: [],
                soilHumidityData: [],
                soilECData: [],
                soilPHData: []
            }
        }
    },
    components: {
        Chart1,
        Chart2
    },
    mounted() {
        Transfer.$on('sensor3', (msg) => {
            this.getSensorOneData(msg.curMachineId, msg.curSensorNum, msg.curSensorType);
            this.getSensorHistoryData(msg.curMachineId, msg.curSensorNum, msg.curSensorType);
        });
    },
    beforeDestroy () {
        
    },
    methods: {
        //获取传感器数据
        getSensorOneData(curMachineId, curSensorNum, curSensorType) {
            let that = this;

            var resData = "?machineId=" + curMachineId + "&sensorNum=" + curSensorNum + "&sensorType=" + curSensorType;
            this.$httpApi.getSensorOne(resData, function(data) {
                // console.log(JSON.stringify(data));
                if(data == null || data.message == null) {
                    return;
                }
                var msg = data.message;
                
                that.titleItem[0].number.number[0] = (msg.batteryLevel == null ? 0 : msg.batteryLevel);
                that.titleItem[1].number.number[0] = (msg.batteryVoltage == null ? 0 : msg.batteryVoltage);
                that.titleItem[2].number.number[0] = (msg.signalStrength == null ? 0 : -msg.signalStrength);

                that.titleItem[0].number = { ...that.titleItem[0].number };
                that.titleItem[1].number = { ...that.titleItem[1].number };
                that.titleItem[2].number = { ...that.titleItem[2].number };
                

                Transfer.$emit('refreshCurSensorData', msg);

            }, function(xhr) {
                console.log(xhr.responseText);
            });
        },
        //获取传感器历史数据
        getSensorHistoryData(curMachineId, curSensorNum, curSensorType) {
            let that = this;
            
            var curDate = new Date();
            curDate.setHours(0);
            curDate.setMinutes(0);
            curDate.setSeconds(0);
            var curDateDay = formatTime(curDate, 'HH:mm:ss')
            var curDateYear = formatTime(curDate, 'yyyy-MM-dd')
            var currentDateStr = curDateYear + " " + curDateDay;
            
            var resData = "?machineId=" + curMachineId + "&sensorNum=" + curSensorNum + "&sensorType=" + curSensorType + "&date=" + currentDateStr;
            this.$httpApi.getSensorHistory(resData, function(data) {
                // console.log(JSON.stringify(data)); 
                if(data == null || data.length == 0) {
                    return;
                }

                //格式化数据
                var datas = that.formatData(data);
                
                //更新页面数据
                that.cdata = datas;
                that.cdata = { ...that.cdata };
                
            }, function(xhr) {
                console.log(xhr.responseText);
            });
        },
        // 根据自己的业务情况修改
        arrayContains(array, item){
            for(var i = 0; i < array.length; i++){
                if(array[i] == item){
                    return true;
                }
            }
            return false;
        },
        formatData (list) {
            var datas = {times:[], soilTempData:[], soilHumidityData:[], soilECData:[], soilPHData:[]};
            for(var i = 0; i < list.length; i++){
                var sensorData = list[i];
                if(sensorData.createTime == null) continue;
                
                var time = sensorData.createTime.substring(11, 13);
                
                if(this.arrayContains(datas.times, time)==false){
                    datas.times.push(time);
                    for(var property in sensorData){
                        if(property=="soilTemp"){
                            datas.soilTempData.push(sensorData.soilTemp);
                        }else if(property=="soilHumidity"){
                            datas.soilHumidityData.push(sensorData.soilHumidity);
                        }else if(property=="soilEC"){
                            datas.soilECData.push(sensorData.soilEC);
                        }else if(property=="soilPH"){
                            datas.soilPHData.push(sensorData.soilPH);
                        }
                        
                    }
                }
                
            }
            
            return datas;
        },


    }
}

</script>
<style lang="scss" scoped>

@import '@/assets/scss/sensor.scss';

</style>
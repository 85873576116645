
<template>
    <div id="sensor2" ref="sensor2" class="body-box">
        
        <div class="data-view-list">
            <div class="bg-color-black item" v-for="(item, index) in titleItem" :key="item.title">
                <p class="colorBlue fw-b fs-xl my-text">{{ item.title }}</p>
                <div v-if="index === 0" style="padding-top:12px;">
                    <span style="color:rgba(0, 255, 255, 0.76);font-weight: bold;font-size:18px;">{{batteryLevelArr[item.number.number]}}</span>
                </div>
                <div v-else>
                    <dv-digital-flop class="my-class dv-dig-flop pl-6" :config="item.number"/>
                </div>
            </div>
        </div>
        <div class="bg-color-black" style="height:450px;">
            <dv-border-box-12>
                <Chart1 :cdata="cdata" />
            </dv-border-box-12>
        </div>
        
    </div>
</template>
<script>

import { formatTime } from '@/utils/index.js'
import Transfer from "@/utils/transfer.js";
import Chart1 from './chart1.vue'

export default {
    data() {
        return {
            batteryLevelArr: ['', '满电', '中等', '低电量', '更换电池或充电'],
            titleItem: [
                {
                    title: '电池电量',
                    number: {
                        number: [1],
                        toFixed: 0,
                        textAlign: 'center',
                        content: '{nt}',
                        style: {
                        fontSize: 26
                        }
                    }
                },
                {
                    title: '电池电压(mv)',
                    number: {
                        number: [15],
                        toFixed: 0,
                        textAlign: 'center',
                        content: '{nt}',
                        style: {
                        fontSize: 26
                        }
                    }
                },
                {
                    title: '信号强度(dBm)',
                    number: {
                        number: [-60],
                        toFixed: 0,
                        textAlign: 'center',
                        content: '{nt}',
                        style: {
                        fontSize: 26
                        }
                    }
                },
                
            ],
            
            cdata: {
                // times: [ "1", "2",  "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"],
                // waterPHData: [20, 25, 25, 26, 22, 20, 15, 18, 16, 28, 30, 33, 35, 32, 31, 36, 28, 25, 20, 14, 15, 13, 11, 12],
                times: [],
                waterPHData: [],
            }
        }
    },
    components: {
        Chart1,
    },
    mounted() {
        Transfer.$on('sensor2', (msg) => {
            this.getSensorOneData(msg.curMachineId, msg.curSensorNum, msg.curSensorType);
            this.getSensorHistoryData(msg.curMachineId, msg.curSensorNum, msg.curSensorType);
        });
    },
    beforeDestroy () {
      
        
    },
    methods: {
        //获取传感器数据
        getSensorOneData(curMachineId, curSensorNum, curSensorType) {
            let that = this;

            var resData = "?machineId=" + curMachineId + "&sensorNum=" + curSensorNum + "&sensorType=" + curSensorType;
            this.$httpApi.getSensorOne(resData, function(data) {
                // console.log(JSON.stringify(data));
                if(data == null || data.message == null) {
                    return;
                }
                var msg = data.message;
                
                that.titleItem[0].number.number[0] = (msg.batteryLevel == null ? 0 : msg.batteryLevel);
                that.titleItem[1].number.number[0] = (msg.batteryVoltage == null ? 0 : msg.batteryVoltage);
                that.titleItem[2].number.number[0] = (msg.signalStrength == null ? 0 : -msg.signalStrength);

                that.titleItem[0].number = { ...that.titleItem[0].number };
                that.titleItem[1].number = { ...that.titleItem[1].number };
                that.titleItem[2].number = { ...that.titleItem[2].number };
                
                Transfer.$emit('refreshCurSensorData', msg);
                
            }, function(xhr) {
                console.log(xhr.responseText);
            });
        },
        //获取传感器历史数据
        getSensorHistoryData(curMachineId, curSensorNum, curSensorType) {
            let that = this;
            
            var curDate = new Date();
            curDate.setHours(0);
            curDate.setMinutes(0);
            curDate.setSeconds(0);
            var curDateDay = formatTime(curDate, 'HH:mm:ss')
            var curDateYear = formatTime(curDate, 'yyyy-MM-dd')
            var currentDateStr = curDateYear + " " + curDateDay;
            
            var resData = "?machineId=" + curMachineId + "&sensorNum=" + curSensorNum + "&sensorType=" + curSensorType + "&date=" + currentDateStr;
            this.$httpApi.getSensorHistory(resData, function(data) {
                // console.log(JSON.stringify(data)); 
                if(data == null || data.length == 0) {
                    return;
                }

                //格式化数据
                var datas = that.formatData(data);
                
                //更新页面数据
                that.cdata = datas;
                that.cdata = { ...that.cdata };
                
            }, function(xhr) {
                console.log(xhr.responseText);
            });
        },
        // 根据自己的业务情况修改
        arrayContains(array, item){
            for(var i = 0; i < array.length; i++){
                if(array[i] == item){
                    return true;
                }
            }
            return false;
        },
        formatData (list) {
            var datas = {times:[], waterPHData:[]};
            for(var i = 0; i < list.length; i++){
                var sensorData = list[i];
                if(sensorData.createTime == null) continue;
                
                var time = sensorData.createTime.substring(11, 13);
                
                if(this.arrayContains(datas.times, time)==false){
                    datas.times.push(time);
                    for(var property in sensorData){
                        if(property=="waterPH"){
                            datas.waterPHData.push(sensorData.waterPH);
                        }
                        
                    }
                }
                
            }
            
            return datas;
        },

    }
}

</script>
<style lang="scss" scoped>

@import '@/assets/scss/sensor.scss';

</style>
<template>
    <div id="sensorList">
      <div class="bg-color-black">
        
        <div style="display:flex;flex-direction: column;">
          <div style="display:flex;flex-direction: row;align-items: center;">
            <span>
              <icon name="chart-line" class="text-icon"></icon>
            </span>
            <div class="d-flex">
              <span class="fs-xl text mx-2">传感器列表 - {{sensorCount}}台</span>
            </div>
          </div>
          <div class="list-header" :style="`background-color: ${config['headerBGC']}};z-index:999;opacity:1;`" @click="transformBegin()">
            <span :style="{width:config.columnWidth[0] + 'px'}">编号</span>
            <span :style="{width:config.columnWidth[1] + 'px'}">类型</span>
            <span :style="{width:config.columnWidth[2] + 'px'}">在线</span>
            <span :style="{width:config.columnWidth[3] + 'px'}">展示</span>
          </div>
        </div>
        
        <div class="body-box">
          <!-- <dv-scroll-board class="dv-scr-board" :config="config" @click="dvScrollBoardClickEvent" /> -->
          <div class="list">
            
            <div id="allListItem">
              <div v-for="(item, index) in config.data" :key="index">
                <div class="list-item" :style="`background-color: ${config[index % 2 === 0 ? 'evenRowBGC' : 'oddRowBGC']};`" @click="dvScrollBoardClickEvent(index)">
                  <span :style="{width:config.columnWidth[0] + 'px'}">{{item[0]}}</span>
                  <!-- <span :style="{width:config.columnWidth[1] + 'px'}">{{item[1]}}</span> -->
                  <div class="list-item-type" :style="{width:config.columnWidth[1] + 'px'}">
                    <span class="type-title" :style="{backgroundColor:sensorTypeArr[item[1]-1].color}">{{item[1]}}</span>
                  </div>
                  <!-- <span :style="{width:config.columnWidth[2] + 'px'}">{{item[2]}}</span> -->
                  <span :style="{width:config.columnWidth[2] + 'px'}">{{item[2] == 1 ? '是' : '否'}}</span>
                  <span :style="{width:config.columnWidth[3] + 'px'}">{{item[3]}}</span>
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <div class="type-list">
            <div v-for="(item, index) in sensorTypeArr" :key="index">
              <div class="type-item" :style="[{color:item.color},{marginTop:item.marginTop}]">
                <span class="type-title" :style="{backgroundColor:item.color}">{{item.sensorType}}</span>
                <span style="width:89%;margin-left:5px;line-height:24px;">{{item.name}}</span>
              </div>
            </div>
            
          </div>
      </div>
    </div>
  </template>
  
  <script>
  import Transfer from "@/utils/transfer.js";
  
  export default {
    data() {
      return {
        timing: null,
        getSensorTiming: null,
        sensorCount: 10,
        endTransform: false,
        curShowIndex: 0,
        sensorTypeArr: [
          {'sensorType': '1', 'color': '#274e13', 'name':'水位水温EC三合一传感器', 'marginTop':''},
          {'sensorType': '2', 'color': '#7f6000', 'name':'水PH传感器', 'marginTop':'15px'},
          {'sensorType': '3', 'color': '#a64d79', 'name':'土壤温湿度ECPH四合一传感器', 'marginTop':'15px'},
          {'sensorType': '4', 'color': '#674ea7', 'name':'土壤温湿度ECPH氮磷钾七合一传感器', 'marginTop':'15px'},
          {'sensorType': '5', 'color': '#3d85c6', 'name':'空气光温湿传感器', 'marginTop':'35px'},
          {'sensorType': '6', 'color': '#cc4125', 'name':'气象站传感器', 'marginTop':'15px'}
        ],
        config: {
          header: ['编号', '类型', '在线', '展示'],
          data: [
            ['编号1', '1', "1", "√"],
            ['编号2', '1', "1", ""],
            ['编号3', '1', "1", ""],
            ['编号4', '1', "1", ""],
            ['编号5', '1', "1", ""],
            ['编号6', '1', "1", ""],
            ['编号7', '1', "1", ""],
            ['编号8', '1', "1", ""],
            ['编号9', '1', "1", ""],
            ['编号10', '1', "1", ""],
          ],
          rowNum: 15, //表格行数
          headerBGC: '#0f1325', //表头
          oddRowBGC: '#0f1325', //奇数行
          evenRowBGC: '#171c33', //偶数行
          index: false,
          columnWidth: [60, 80, 60, 60],
          align: ['center', 'center', 'center', 'center'],
          waitTime: 3000,
        }
      }
    },
    mounted() {
      this.changeTiming();
      this.changeRefreshTiming();
      this.getData();
    },
    beforeDestroy () {
      if(this.timing != null) {
        clearInterval(this.timing);
        this.timing = null;
      }
      if(this.getSensorTiming != null) {
        clearInterval(this.getSensorTiming);
        this.getSensorTiming = null;
      }
        
    },
    methods: {
      changeTiming() {
        this.timing = setInterval(() => {
          var globalData = this.$store.state.globalData;
          if(globalData.machineLoop == false) return;

          this.changeDisplay();
          
          this.transformFun();
          
        }, this.$GLOBAL.deviceShowTiming);
      },
      changeRefreshTiming() {
        this.getSensorTiming = setInterval(() => {
          this.getData();
        }, this.$GLOBAL.deviceRefreshTiming);
      },

      //位移事件
      transformFun() {
        //向上滚动
        if(this.config.data.length <= this.config.rowNum) {
          return;
        }

        var ele = document.getElementById("allListItem");
        var top = document.getElementsByClassName("list-item")[0].clientHeight;

        if(this.curShowIndex >= (this.config.data.length - this.config.rowNum + 1) && this.curShowIndex <= (this.config.data.length  - 1)) {
          //位移到最后一页，确保展示最后一页，执行一次
          if(this.endTransform == false) {
            top *= this.config.data.length - this.config.rowNum;
            top = -top;
            ele.style.transform = "translateY(" + top + "px)";
            ele.style.transition = "all 1s";

            this.endTransform = true;
          }
          return;
        }
        this.endTransform = false;
        
        //开始位移
        top *= this.curShowIndex;
        top = -top;
        ele.style.transform = "translateY(" + top + "px)";
        ele.style.transition = "all 1s";
      },
      //回到顶部
      transformBegin() {
        this.changeDisplay(this.config.data[0][0], 0);
        this.transformFun();
      },
      //设备列表点击事件
      dvScrollBoardClickEvent(ind) {
        // console.log(JSON.stringify(d));

        //停止定时器
        if(this.timing != null) {
          clearInterval(this.timing);
          this.timing = null;
        }

        //修改展示设备
        var d = this.config.data[ind];
        var rowIndex = ind;
        var toMachine = d[0];
        this.changeDisplay(toMachine, rowIndex);
        
        this.transformFun();
      },

      changeDisplay(toSensorNum, toRowIndex) {
        var curIndex = 0;
        for(var i = 0; i < this.config.data.length; i++) {
          var item = this.config.data[i];
          if(item[3] == "√") {
            curIndex = i;
            break;
          }
        }
        if(curIndex == toRowIndex) return;

        this.config.data[curIndex][3] = ""; 

        if(toSensorNum == undefined) {
          if((curIndex + 1) >= this.config.data.length) {
            curIndex = 0;
          } else {
            curIndex++;
          }
        } else {
          curIndex = toRowIndex;
        }
        this.preShowIndex = this.curShowIndex;
        this.curShowIndex = curIndex;

        this.config.data[curIndex][3] = "√";
        
        this.$store.commit("setCurSensorNum", this.config.data[curIndex][0]);
        this.$store.commit("setCurSensorType", this.config.data[curIndex][1]);
        
        this.config = { ...this.config };
        
        //重置设备传感器轮训定时
        if(this.timing != null) {
          clearInterval(this.timing);
          this.timing = null;
        }
        //重启定时器
        this.changeTiming();
        
        //触发父组件事件
        var msg = {};
        msg.curSensorNum = this.config.data[curIndex][0];
        msg.curSensorType = this.config.data[curIndex][1];
        Transfer.$emit('navDataScreen', msg);

        //触发布局图 传感器点位效果
        Transfer.$emit('refreshLayoutMapPointActive', msg);

      },

      getData() {
        var globalData = this.$store.state.globalData;
        if(globalData.curGroupId == null && (globalData.curGroupName == '' || globalData.curGroupName == null)) return;
        
        let that = this;
        var resData = "?groupId=" + globalData.curGroupId + "&groupName=" + globalData.curGroupName;
        if(globalData.curGroupId == null) {
          resData = "?groupId=&groupName=" + globalData.curGroupName;
        }
        
        
        this.$httpApi.getSensorByGroup(resData, function(data) {
          // console.log(JSON.stringify(data)); 
          var arr = [];
          var showIndex = 0;
          
          if(data.length <= 0) {
            return;
          }
          
          var sensorList = data.sensorList;
          if(sensorList.length <= 0) {
            return;
          }
          that.sensorCount = sensorList.length;

          //排序
          var sortKeys = Object.keys(sensorList).sort((a, b) => {
            return sensorList[a].sensorNum - sensorList[b].sensorNum;
          });
          // 循环排列好的 key, 重新组成一个新的数组
          var sensorArr = [];
          for (var sortIndex in sortKeys) {
            sensorArr.push(sensorList[sortKeys[sortIndex]])
          }
    
          //坐标数组
          var sensorCoordinateArr = [];
          for(var i = 0; i < sensorArr.length; i++) {
            var item = sensorArr[i];
            var tmpObj = {};
            
            tmpObj.sensorNum = item.sensorNum;
            tmpObj.sensorType = item.sensorType;
            tmpObj.sensorStatus = item.sensorStatus;
            tmpObj.coordinateX = item.coordinateX;
            tmpObj.coordinateY = item.coordinateY;
            sensorCoordinateArr[i] = tmpObj;
            
            var arrData = [];
            arrData[0] = item.sensorNum;
            arrData[1] = item.sensorType;
            // arrData[2] = (item.sensorStatus == 1 ? "是" : "否");
            arrData[2] = item.sensorStatus;
            arrData[3] = "";
            arr[i] = arrData;

            if(globalData.curSensorNum == item.sensorNum) {
              showIndex = i;
            }
            
          }
          arr[showIndex][3] = "√";

          that.$store.commit("setCurMachineId", data.machineId);
          that.$store.commit("setCurOnline", data.online);
          that.$store.commit("setCurSensorNum", arr[showIndex][0]);
          that.$store.commit("setCurSensorType", arr[showIndex][1]);
          
          that.$store.commit("setCoordinateX", data.latitude);
          that.$store.commit("setCoordinateY", data.longitude);
          that.$store.commit("setSensorCoordinateArr", sensorCoordinateArr);

          Transfer.$emit('refreshLayoutMap', "");

          
          that.config.data = arr;
          that.config = { ...that.config };

          //触发父组件事件
          var msg = {};
          msg.curMachineId = data.machineId;
          msg.curOnline = data.online;
          Transfer.$emit('showCurMachineId', msg);

          //触发更新数据
          setTimeout(() => {
            msg = {};
            msg.curSensorNum = arr[showIndex][0];
            msg.curSensorType = arr[showIndex][1];
            Transfer.$emit('navDataScreen', msg);
          }, 200);
         
          
        }, function(xhr) {
          console.log(xhr.responseText);
        });
        
      }
    }
  }
</script>
  
<style lang="scss" scoped>

@import '@/assets/scss/sensorlist.scss';
  
</style>
  
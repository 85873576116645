
<template>
    <div>
      <Echart
        :options="options"
        id="sensor1Chart1"
        height="440px"
        width="100%"
      ></Echart>
    </div>
  </template>
  
  <script>
  import Echart from '@/common/echart'
  export default {
    data () {
      return {
        options: {},
      };
    },
    components: {
      Echart,
    },
    props: {
      cdata: {
        type: Object,
        default: () => ({})
      },
    },
    watch: {
      cdata: {
        handler (newData) {
          this.options = {
            tooltip: {
              trigger: 'axis',
            },
            legend: {
              data: ['水温(℃)', '营养指数(uS/cm)'],
              textStyle: {
                color: "#B4B4B4"
              },
              top: "5%"
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: {
              //name:'时间',
              type: 'category',
              boundaryGap: false,
              data: newData.times,
            },
            yAxis: {
              type: 'value',
              splitLine: {
                show: true,
                lineStyle: {
                  color: '#fff',
                  opacity: 0.1
                }
              },
            },
            series: [
              {
                name: '水温(℃)',
                type: 'line',
                // stack: 'Total',
                data: newData.waterTempData
              },
              {
                name: '营养指数(uS/cm)',
                type: 'line',
                // stack: 'Total',
                data: newData.waterECData
              }
            ]
          }
        },
        immediate: true,
        deep: true
      },
    },
  }
  </script>

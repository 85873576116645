

<template>
    <div>
      <Echart
        :options="options"
        id="sensor5Chart2"
        height="440px"
        width="100%"
      ></Echart>
    </div>
</template>

<script>

import Echart from '@/common/echart'
export default {
    data () {
      return {
        options: {},
      };
    },
    components: {
      Echart,
    },
    props: {
      cdata: {
        type: Object,
        default: () => ({})
      },
    },
    watch: {
      cdata: {
        handler (newData) {
          this.options = {
            title: {
                text: "光照强度(LX)",
                textStyle: {
                    color: '#D3D6DD',
                    fontSize: 12,
                    fontWeight: 'normal'
                },
                top: 20,
                left: 20
            },
            tooltip: {
              trigger: 'axis',
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: newData.times,
            },
            yAxis: {
              type: 'value',
              splitLine: {
                show: true,
                lineStyle: {
                  color: '#fff',
                  opacity: 0.1
                }
              },
            },
            series: [
              {
                name: '光照强度(LX)',
                type: 'line',
                // stack: 'Total',
                areaStyle: {},
                data: newData.envIlluminationData
              }
            ]
          }
        },
        immediate: true,
        deep: true
      },
    },
}
</script>

